<template>
  <div class="service-manage-container">
    <v-title>
      <Breadcrumb>
        <Breadcrumb-item>
          <i class="icon-home"></i>咨询管理
        </Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <ul class="header-nav">
      <li>
        <router-link to="/base/advisory-manage">咨询管理</router-link>
      </li>
      <li>
        <router-link to="/base/advisory-manage/questions">问题管理</router-link>
      </li>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';

export default {
	components: {
		'v-title': vTitle,
	},
};
</script>

<style lang="less" scoped>
.header-nav {
  margin: 20px 0 0 16px;
  font-size: 16px;
  li {
    display: inline-block;
    margin-right: 20px;
    a {
      color: #000;
      &.router-link-exact-active {
        color: #fdad00;
      }
    }
  }
}
</style>
